<template>
  <TextElement
    :tag-name="tagName"
    :value="value"
    :attr="attr"
    :element="element"
    :style="textStyle"
    :is-editor-mode="isEditorMode"
    :page-data="pageData"
    :page-options="pageOptions"
    class="tw-translation-element"
  />
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import type { Element as ElementType, PageOptions } from '../../types/model';
import type { ElementOptions } from '../../types/options';
import TextElement from '../text/index.vue';

const props = defineProps({
  tagName: { type: String, default: 'p' },
  value: { type: String, default: '' },
  attr: { type: Object as PropType<ElementType<'conditional-text'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'conditional-text'>>, default: () => ({}) },
  style: { type: Object as PropType<ElementType<'conditional-text'>['style']>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  pageData: { type: Object, default: () => ({ isLoading: false, isDisabled: undefined }) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});

const options = computed(() => props.element.options as ElementOptions['translation'])

const textStyle = computed(() => {
  return {
    ...props.style,
    fontWeight: options.value?.textStyle?.bold ? '600' : '400',
    fontStyle: options.value?.textStyle?.italic ? 'italic' : 'normal',
    textDecoration: `${options.value?.textStyle?.underline ? 'underline' : ''} ${options.value?.textStyle?.strikeThrough ? 'line-through' : ''}`.trim()
  }
});

</script>
